#accountpage .accountBox {
  border: 4.38px solid #000000;
}
.checkoutsNav {
  z-index: 1;
}
.checkoutsNav::before {
  content: "";
  position: absolute;
  height: 1px;
  background: #979494;
  margin-left: auto;
  margin-right: auto;
  bottom: 1px;
}
.checkoutsNav button {
  z-index: 999;
  padding: 5px 65px;
}
.orderBox {
  background: #ffffff;
  border: 4.38px solid #000000;
  height: 180px;
}
.bookingBox {
  background: #ffffff;
  border: 4.38px solid #000000;
  height: 180px;
}
.orderBoxImg {
  width: 220px;
  min-width: 220px;
  height: 100%;
  overflow: hidden;
}
.orderBoxBtn {
  border: 2.96429px solid #000000;
  width: 315px;
}
.personalizationBox {
  border: 4.38px solid #000000;
}
.personalizationBox .MuiFormControl-root {
  border: 3px solid #000000;
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.2rem;
  margin-bottom: 1.8rem;
}
.fidelityPointsBtn {
  border: 3px solid #00b27a;
  justify-content: center;
  display: flex;
  align-items: center;
}
.fidelityCardBtn {
  border: 3px solid #000000;
  width: 277px;
}
.fidelityPercentage {
  width: 249.07px;
  height: 3.79px;
  background: linear-gradient(0deg, #eeeeee, #eeeeee),
    linear-gradient(0deg, #eeeeee, #eeeeee), #eeeeee;
  border-radius: 25.2866px;
}
.fidelityPercentage span {
  background: #ffa563;
  border-radius: 25.2866px;
  width: 14.73px;
  height: 3.79px;
}
.fidelityDiscount {
  width: 249.07px;
  height: 3.79px;
  background: linear-gradient(0deg, #eeeeee, #eeeeee),
    linear-gradient(0deg, #eeeeee, #eeeeee), #eeeeee;
  border-radius: 25.2866px;
}
.fidelityDiscount span {
  background: #ffa563;
  border-radius: 25.2866px;
  width: 70.8px;
  height: 3.79px;
}

#navbarprofile {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

#navbarprofile::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.forceWhiteText {
  color: white !important;
}
