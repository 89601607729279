.modal::-webkit-scrollbar {
  display: none;
}
.scroll::-webkit-scrollbar {
  height: 8px;
  width: 3px;
}

/* The Client Carousel CSS */
.customCss {
  background-color: var(--primary-color) !important;
  height: 15px !important;
  width: 15px !important;
}


.swiper-wrapper {
  transition-duration: 500ms !important;
}

/* The X rotation of the Profile Modals */
.Xrotate {
  transition: transform 0.2s ease-in-out;
}
.Xrotate:hover {
  transform: rotate(90deg);
}
.trashCss {
  transition: transform 0.2s ease-in-out;
}
.trashCss:hover {
  transform: scale(1.4);
}
.imge:hover > img {
  transform: rotate(90deg);
}
/* .noodlesstick {
  width: 350px;
  height: 480px;
  top: 225px;
  right: 0;
  position: absolute;
}
.singlesushi {
  width: 192.95px;
  height: 181.06px;
  top: 120.8px;
  right: 0;
  position: absolute;
}
.sushiRolls {
  width: 300px;
  height: 350px;
  top: 80%;
  left: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  right: 12em;
} */
.newFont {
  font-family: "Pacifico", cursive;
}
