@tailwind base;
@tailwind components;
@tailwind utilities;

.root{
  --primary-color: #928471;
  --primary-color-hover: #AF9F88;
  --primary-color-active: #8F826F;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
