.Hero {
  position: relative;
  height: 80vh;
}

@media (max-width: 767px) {
  .Hero {
    height: min-content;
    padding-block: 96px;
  }
}

.Hero__wrapper {
  display: flex;
  height: 100%;
}

.Hero__hamburger {
  display: none;
}

@media (max-width: 1199px) {
  .Hero__hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: 16px;
    right: 16px;
    background-color: #2b4f10;
  }

  .Hero__hamburger > svg {
    cursor: pointer;
    color: #ffffff;
  }
}

.Hero__sidebar {
  position: absolute;
  left: 0;
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 48px;
  background-color: #F2AF18;
}

@media (max-width: 1199px) {
  .Hero__sidebar {
    display: none;
  }
}

.Hero__sidebar-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
}

.Hero__list-item {
  color: #ffffff;
  text-transform: uppercase;
}

.Hero-item--active {
  border-bottom: 1px solid #ffffff;
}

.Hero__informations {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  padding: 0 72px;
  margin-left: auto;
  height: 100%;
}

@media (max-width: 1199px) {
  .Hero__informations {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .Hero__informations {
    padding: 0 36px;
  }
}

@media (max-width: 767px) {
  .Hero__informations > div {
    flex-direction: column;
  }
}

.Hero__img {
  height: 520px;
}



.Hero__title {
  text-align: left;
  color: #ffffff;
  max-width: 320px;
  line-height: 1;
  text-transform: uppercase;
  border-bottom: 2px solid #F2AF18;
}

@media (max-width: 767px) {
  .Hero__title {
    max-width: 100%;
    text-align: center;
  }
}

@media (max-width: 575px) {
  .Hero__title {
    font-size: 40px;
    border-bottom: none;
  }
}

.Hero__text {
  text-align: left;
  margin-top: 32px;
  color: #ffffff;
  max-width: 620px;
}

@media (max-width: 767px) {
  .Hero__text {
    text-align: center;
  }
}

.Hero__description {
  margin-top: 32px;
}

.Hero__description-label {
  color: #ffffff;
  text-align: left;
  font-size: 20px;
}

.Hero__list {
  display: flex;
  margin-top: 16px;
}

@media (max-width: 767px) {
  .Hero__list {
    flex-wrap: wrap;
  }

  .Hero__item {
    border-radius: 0px !important;
  }

  .Hero__item:nth-child(1) {
    width: 50%;
  }

  .Hero__item:nth-child(2) {
    width: 50%;
    border-right: none !important;
  }

  .Hero__item:nth-child(3) {
    width: 50%;
  }

  .Hero__item:nth-child(4) {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .Hero__item:nth-child(1) {
    width: 100%;
    border-bottom: 1px solid #ffffff;
    border-right: none !important;
  }

  .Hero__item:nth-child(2) {
    border-bottom: 1px solid #ffffff;
    width: 100%;
  }

  .Hero__item:nth-child(3) {
    border-bottom: 1px solid #ffffff;
    width: 100%;
    border-right: none !important;
  }

  .Hero__item:nth-child(4) {
    width: 100%;
  }
}

.Hero__item {
  background-color: #2b4f10;
  padding: 12px 32px;
}

.Hero__item:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.Hero__item > span {
  color: #ffffff;
  font-weight: 400;
}

.Hero__item-label {
  font-weight: bold !important;
}

.Hero__item:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Hero__item:not(:last-child) {
  border-right: 1px solid #ffffff;
}

.Hero__option {
  position: absolute;
  bottom: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Hero__option-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  cursor: pointer;
}

.Hero__option-item > svg {
  color: #ffffff;
}
