.SidebarMobile {
  position: absolute;
  left: 0;
  width: 260px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 48px;
  background-color: #2b4f10;
}
