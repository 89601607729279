
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');
@import url('https://fonts.cdnfonts.com/css/capture-it');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap');
body {
  box-sizing: border-box;
}
h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  color: #1f212f;
  font-size: 5em;
}

h2,
h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #1f212f;
  font-size: 3em;
}

h4,
h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #1f212f;
  font-size: 2em;
}

h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: white;
  font-size: 1.6em;
  font-style: normal;
}

.App {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow: hidden;
}

/*scrollbar*/

html {
  overflow: hidden;
}

html:hover {
  overflow-y: overlay;
}

.scroller {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* scrollbar-color: var(--primary-color) #ffffff; */
  scrollbar-width: thin;
}

::-webkit-html {
  overflow: hidden;
}

::-webkit-html:hover {
  overflow-y: overlay;
}

@media (max-width: 834px) {
  html {
    overflow-y: visible;
  }

  html:hover {
    overflow-y: visible;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: 1px solid #eee;
  height: 100px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a59580;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiFormControlLabel-label:hover {
  color: black;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;

  margin-bottom: 15px;
  padding: 20px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
/* .swiper-wrapper {
  padding: 10px;
} */
.border-button:after {
  display: block;
  margin-top: 8px;
  content: "";
  border-bottom: solid 2px var(--primary-color);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.border-button:hover:after {
  transform: scaleX(1);
}
html {
  scroll-behavior: smooth;
}

/* ///////website not accessible CSS////////////// */
/* ErrorMessageStyles.css */
#errorContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#errorContent {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

#error404 {
  height: 280px;
  position: relative;
  z-index: -1;
}
#error404 h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 6vw; 
  margin: 0;
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: url('./Assets/Image/websitenotaccessible.jpg') no-repeat;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
  white-space: nowrap; 
}
#errorHeading {
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

#errorText {
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0;
}

#errorLink {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #0046d5;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
  box-shadow: 0px 4px 15px -5px #0046d5;

  /* Custom cursor style for the anchor tag */
  cursor: pointer;
}
